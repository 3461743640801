import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import '../home.css';
import { useGlobalContext } from './Context';
import throttle from 'lodash/throttle';
import 'typeface-inter';

const SvgTeam = () => {
	const { stats, local, matchId, compId, test } = useGlobalContext();
	const [useExt, setUseExt] = useState(false);
	const [framesText, setFramesText] = useState(null);
	const framesLength = 185;
	const [p1Text, setp1Text] = useState(null);
	const p1Length = 701;
	const [p2Text, setp2Text] = useState(null);
	const [venueNameText, setVenueNameText] = useState(null);
	const venueNameLength = 700;
	const [compNameText, setCompNameText] = useState(null);
	const compNameLlength = 700;

	const calculateTextLength = useCallback(
		throttle(() => {
			const frames = document.getElementById('frames');
			if (frames) {
				const computedLength = frames.getComputedTextLength();
				setFramesText(computedLength > framesLength ? framesLength : null);
			}
			const p1Text = document.getElementById('p1Text');
			if (p1Text) {
				const computedLength = p1Text.getComputedTextLength();
				setp1Text(computedLength > p1Length ? p1Length : null);
			}
			const p2Text = document.getElementById('awayTeamLabel');
			if (p2Text) {
				const computedLength = p2Text.getComputedTextLength();
				setp2Text(computedLength > p1Length ? p1Length : null);
			}
			const venueName = document.getElementById('venueName');
			if (venueName) {
				const computedLength = venueName.getComputedTextLength();
				setVenueNameText(
					computedLength > venueNameLength ? venueNameLength : null
				);
			}

			const compNameText = document.getElementById('compName');
			if (compNameText) {
				const computedLength = compNameText.getComputedTextLength();
				setCompNameText(
					computedLength > compNameLlength ? compNameLlength : null
				);
			}
		}, 300),
		[matchId, local, p1Length, framesLength]
	);

	useEffect(() => {
		calculateTextLength();
	}, [matchId, local, p1Length, framesLength, calculateTextLength]);

	const calculateProgress = () => {
		if (!stats[0]?.timer || !stats[0]?.totalTime) return 0;
		return (stats.timer / stats.totalTime) * 100; // Calculate progress as a percentage
	};

	return (
		<svg
			id='Layer_1'
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
			viewBox='0 0 1651 179'
			style={{ transform: 'scaleX(1.1) scaleY(1.4)' }}
			width='80vw'
		>
			<defs>
				<clipPath id='clippath'>
					<rect
						width={1651}
						height={169}
						style={{
							fill: 'none',
						}}
					/>
				</clipPath>
				<linearGradient
					id='linear-gradient'
					x1={728.86}
					y1={-358.1}
					x2={921.22}
					y2={-369.85}
					gradientTransform='translate(0 -334) scale(1 -1)'
					gradientUnits='userSpaceOnUse'
				>
					<stop
						offset={0}
						stopColor='#f7f5f5'
					/>
					<stop
						offset={0.54}
						stopColor='#d9d8d8'
					/>
				</linearGradient>
			</defs>
			<g
				style={{
					clipPath: 'url(#clippath)',
				}}
			>
				<g>
					<path d='M29.24,7.68C31.89,2.94,36.9,0,42.33,0h1582.48c11.7,0,18.9,12.8,12.82,22.8l-18.26,30c-2.72,4.47-7.58,7.2-12.81,7.2H25.57c-11.45,0-18.68-12.32-13.09-22.32,0,0,16.76-30,16.77-30Z' />
					<path
						d='M30.15,9.39c2.7-4.58,7.61-7.38,12.92-7.39h674.43v56H27.75c-11.61,0-18.82-12.62-12.92-22.62,0,0,15.32-26,15.32-26Z'
						style={{
							fill: '#1c1c1c',
						}}
					/>
					<path
						d='M717.5,2h904.94c11.78,0,18.96,12.96,12.72,22.95l-16.25,26c-2.74,4.39-7.55,7.05-12.72,7.05h-888.69V2Z'
						style={{
							fill: '#1c1c1c',
						}}
					/>
					<path
						d='M30.66,9.57c2.67-4.68,7.64-7.57,13.03-7.57h1580.31c9.86,0,16.84,9.63,13.77,19-.39,1.19-1.5,1.99-2.75,2H23l7.66-13.43Z'
						style={{
							fill: 'rgba(217, 217, 217, .2)',
							opacity: 0.35,
						}}
					/>
					<rect
						x={717.5}
						y={5}
						width={216}
						height={50}
						rx={15}
						ry={15}
						style={{
							fill: 'url(#linear-gradient)',
						}}
					/>
					<g>
						<path d='M706,80h218v46h-218v-46Z' />
						<path
							d='M708,82h214v42h-214v-42Z'
							style={{
								fill: '#fff',
							}}
						/>
						<path
							d='M646,95c0-8.28,6.72-15,15-15h45v46h-45c-8.28,0-15-6.72-15-15v-16Z'
							style={{
								fill: '#42d70e',
							}}
						/>
						<path
							d='M924,80h65c8.28,0,15,6.72,15,15v16c0,8.28-6.72,15-15,15h-65v-46Z'
							style={{
								fill: '#1c1c1c',
							}}
						/>
						<path
							d='M646,101c0-11.6,9.4-21,21-21h322c8.28,0,15,6.72,15,15v6h-358Z'
							style={{
								fill: 'rgba(217, 217, 217, .08)',
								opacity: 0.35,
							}}
						/>
						<path
							d='M649.83,112l3.14-18.91h13.18l-.63,3.71h-8.61l-.63,3.88h7.94l-.63,3.72h-7.94l-.63,3.89h8.61l-.63,3.71s-13.19,0-13.19,0ZM673.47,93.09l2.46,5.97h.15l4.49-5.97h5.29l-7.26,9.45,4.3,9.46h-5.12l-2.55-6.04h-.15l-4.54,6.04h-5.33l7.45-9.46-4.16-9.45h4.98ZM686.97,96.8l.63-3.71h15.98l-.63,3.71h-5.73l-2.51,15.2h-4.51l2.51-15.2s-5.74,0-5.74,0Z'
							style={{
								fill: '#fff',
							}}
						/>
					</g>
					<g>
						<path d='M25.38,81.38c2.52-5.13,7.74-8.38,13.46-8.38h533.51c11.51,0,18.73,12.42,13.04,22.42l-17.07,30c-2.67,4.69-7.65,7.58-13.04,7.58H24.09c-11.11,0-18.36-11.65-13.46-21.62l14.75-30Z' />
						<path
							d='M27.83,85.19c2.56-5.03,7.72-8.19,13.36-8.19h218.81v52H25.08c-8.96,0-14.76-9.46-10.69-17.45l13.44-26.36h0Z'
							style={{
								fill: '#1c1c1c',
							}}
						/>
						<path
							d='M260,77h310.74c9.12,0,14.91,9.77,10.52,17.77l-14.49,26.44c-2.63,4.8-7.68,7.79-13.15,7.79h-293.62v-52Z'
							style={{
								fill: '#1c1c1c',
							}}
						/>
						<path
							d='M27.51,85.82c2.43-5.37,7.78-8.82,13.67-8.82h528.45c10.2,0,16.29,11.37,10.63,19.86-.47.71-1.27,1.14-2.13,1.14H22l5.51-12.18Z'
							style={{
								fill: 'rgba(217, 217, 217, .2)',
								opacity: 0.35,
							}}
						/>
					</g>
					<g>
						<path d='M1067.38,81.38c2.52-5.13,7.74-8.38,13.46-8.38h533.51c11.5,0,18.72,12.42,13.03,22.42l-17.07,30c-2.66,4.69-7.64,7.58-13.03,7.58h-531.19c-11.11,0-18.36-11.65-13.46-21.62l14.75-30h0Z' />
						<path
							d='M1069.83,85.19c2.56-5.03,7.72-8.19,13.36-8.19h218.81v52h-234.92c-8.96,0-14.76-9.46-10.69-17.45l13.44-26.36h0Z'
							style={{
								fill: '#1c1c1c',
							}}
						/>
						<path
							d='M1302,77h310.74c9.12,0,14.91,9.77,10.52,17.77l-14.49,26.44c-2.63,4.8-7.67,7.79-13.15,7.79h-293.62v-52Z'
							style={{
								fill: '#1c1c1c',
							}}
						/>
						<path
							d='M1069.51,85.82c2.43-5.37,7.78-8.82,13.67-8.82h528.45c10.2,0,16.29,11.37,10.63,19.86-.48.71-1.27,1.14-2.13,1.14h-556.13l5.51-12.18Z'
							style={{
								fill: 'rgba(217, 217, 217, .2)',
								opacity: 0.35,
							}}
						/>
					</g>
				</g>
			</g>
			<text
				style={{
					isolation: 'isolate',
					fontSize: 60,
					fill: 'white',
					fontFamily: 'Inter-BoldItalic',
					// fontVariant: 'small-caps',
					fontStyle: 'italic',
					fontWeight: '500',
					textAlign: 'center',
					textAnchor: 'middle',
				}}
				transform='matrix(.7 0 0 1 1006.85 52.5)'
			>
				<tspan
					x={0}
					y={0}
				>
					{local === 'true' || local === true
						? stats?.away?.score
						: stats[0]?.awayscore}
				</tspan>
			</text>
			<text
				style={{
					isolation: 'isolate',
					fontSize: 60,
					fill: 'white',
					fontFamily: 'Inter-BoldItalic',
					fontStyle: 'italic',
					// fontVariant: 'small-caps',
					fontWeight: '500',
					textAlign: 'center',
					textAnchor: 'middle',
				}}
				transform='matrix(.7 0 0 1 636.08 52.5)'
			>
				<tspan
					x={0}
					y={0}
				>
					{local === 'true' || local === true
						? stats?.home?.score
						: stats[0]?.homescore}
				</tspan>
			</text>
			<text
				style={{
					isolation: 'isolate',
					fontSize: 40,
					fill: 'white',
					fontFamily: 'Inter-BoldItalic',
					fontStyle: 'italic',
					// fontVariant: 'small-caps',
					fontWeight: '500',
					textAlign: 'center',
					textAnchor: 'middle',
				}}
				transform='matrix(.7 0 0 1 962 118)'
			>
				<tspan
					x={0}
					y={0}
				>
					{local === 'true' || local === true ? stats?.timer : ''}
				</tspan>
			</text>
			<foreignObject
				x='708.5'
				y='82'
				width='214'
				height='42'
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						zIndex: 100,
						position: 'relative',
					}}
				>
					<ProgressBar
						completed={stats.timer && stats.totalTime ? (stats.timer / stats.totalTime) * 100 : 0} // Calculate percentage remaining as percentage
						customLabel={`${Math.ceil(stats.totalTime - stats.timer)}s`} // Show remaining time
						labelSize={30}
						labelColor='black'
						labelAlignment='center'
						height={42}
						width={215}
						borderRadius={2}
						baseBgColor='white'
						bgColor='red'
						isLabelVisible={false} // Show label
						transitionDuration='1s' // Update every second
						transitionTimingFunction='linear'
						animateOnRender={true}
						dir='ltr' // Progress starts from left
						isIndeterminate={false} // Ensure determinate mode
					/>
				</div>
			</foreignObject>
			<text
				style={{
					isolation: 'isolate',
					fontSize: 53,
					fill: '#fff',
					fontFamily: 'Inter-BoldItalic',
					fontStyle: 'italic',
					// fontVariant: 'small-caps',
					fontWeight: '500',
					textAlign: 'center',
					textAnchor: 'middle',
				}}
				id='p1Text'
				transform='matrix(.7 0 0 1 288.55 46)'
				textLength={p1Text}
				lengthAdjust='spacingAndGlyphs'
			>
				<tspan
					x={0}
					y={0}
				>
					{local === 'true' || local === true
						? stats?.home?.name
						: stats[0]?.hometeamlabel}
				</tspan>
			</text>
			<text
				style={{
					isolation: 'isolate',
					fontSize: 53,
					fill: '#fff',
					fontFamily: 'Inter-BoldItalic',
					fontStyle: 'italic',
					// fontVariant: 'small-caps',
					fontWeight: '500',
					textAlign: 'center',
					textAnchor: 'middle',
				}}
				transform='matrix(.7 0 0 1 1348.93 46)'
				textLength={p2Text}
				lengthAdjust='spacingAndGlyphs'
				id='awayTeamLabel'
			>
				<tspan
					x={0}
					y={0}
				>
					{local === 'true' || local === true
						? stats?.away?.name
						: stats[0]?.awayteamlabel}
				</tspan>
			</text>
			<text
				style={{
					isolation: 'isolate',
					fontSize: 53,
					fill: '#fff',
					fontFamily: 'Inter-BoldItalic',
					fontStyle: 'italic',
					// fontVariant: 'small-caps',
					fontWeight: '500',
					textAlign: 'center',
					textAnchor: 'middle',
				}}
				transform='matrix(.7 0 0 1 290 120)'
				textLength={venueNameText}
				lengthAdjust='spacingAndGlyphs'
				id='venueName'
			>
				<tspan
					x={0}
					y={0}
				>
					{local === 'true' || local === true
						? ''
						: stats[0]
						? stats?.venueabbr
						: ''}
				</tspan>
			</text>
			<text
				style={{
					isolation: 'isolate',
					fontSize: 53,
					fill: '#fff',
					fontFamily: 'Inter-BoldItalic',
					fontStyle: 'italic',
					// fontVariant: 'small-caps',
					fontWeight: '500',
					textAlign: 'center',
					textAnchor: 'middle',
				}}
				textLength={compNameText}
				lengthAdjust='spacingAndGlyphs'
				transform='matrix(.7 0 0 1 1340 120)'
				id='compName'
			>
				<tspan
					x={0}
					y={0}
				>
					{local === 'true' || local === true
						? ''
						: stats[0]
						? stats?.compname
						: ''}
				</tspan>
			</text>
			<text
				style={{
					isolation: 'isolate',
					fontSize: 43.5,
					fill: '#231f20',
					fontFamily: 'Inter-BoldItalic',
					fontStyle: 'italic',
					fontWeight: 'bold',
					textAlign: 'center',
					verticalAlign: 'middle',
					textAnchor: 'middle',
				}}
				transform='matrix(.7 0 0 1 822 47)'
				id='frames'
				textLength={framesText}
				lengthAdjust='spacingAndGlyphs'
			>
				{local === 'true' || local === true
					? stats?.raceLength
					: stats[0]?.matchformat}
			</text>
		</svg>
	);
};
// {stats[0]?.awayframepointsadj===0 && stats[0]?.awayscorepoints===0 ? stats?.awayscore : `${awayScore}`}
// {stats[0]?.homeframepointsadj===0 && stats[0]?.homescorepoints===0 ? stats?.homescore : `${homeScore}`}
// {stats[0]?.homescorepoints>0 ? `${stats[0]?.homescore}` : ''}
// {stats[0]?.awayscorepoints>0 ? `${stats[0]?.awayscore}` : ''}
export { SvgTeam };
